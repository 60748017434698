const routes = {
  // landing and related
  root: () => "/",
  about: () => "/about",

  alert: (id: string) => `/alerts/${id}`,
  unsubscribe: (id: string) => `/unsubscribe/${id}`,
  dashboard: () => "/dashboard",
  create: () => "/create",

  // auth
  login: () => "/login",
  signup: () => "/signup",
  forgot: () => "/forgot",
  reset: () => "/reset",
};

export default routes;
