<template>
  <div
    class="flex h-[var(--footer-height)] items-center justify-between bg-gray-800 px-[var(--layout-padding-x)] text-sm text-gray-400"
  >
    <div class="flex items-center gap-2 sm:gap-4">
      <RouterLink :to="Routes.root()">
        <img src="@/assets/wordmark-light.svg" class="mb-1 h-5 sm:mb-1.5 sm:h-8" />
      </RouterLink>
      <div><span class="hidden sm:inline">made in Seattle, </span>©{{ new Date().getFullYear() }}</div>
    </div>

    <div>
      <RouterLink :to="Routes.root()" class="link-hover link">Home</RouterLink>
      &bull;
      <RouterLink :to="Routes.about()" class="link-hover link">About</RouterLink>
    </div>
  </div>
</template>
