import type { Hello, User } from "@/lib/models";
import { apiAuth } from "@/vendor/api";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("user", () => {
  const hello = inject<Hello>("hello");
  if (!hello) throw new Error("impossible");

  //
  // state
  //

  const user = ref<User | undefined>(hello.user);
  const loggedIn = computed(() => !!user.value);

  //
  // actions
  //

  async function login(data: { email: string; password: string }) {
    user.value = await apiAuth.login({ data });
  }

  async function loginWithGoogle(data: { code: string }) {
    user.value = await apiAuth.loginWithGoogle({ data });
  }

  async function signup(data: { email: string; name: string; password: string }) {
    user.value = await apiAuth.signup({ data });
  }

  //
  // api delegates
  //

  async function forgot(data: { email: string }) {
    await apiAuth.forgot({ data });
  }

  async function reset(data: { password: string; token: string }) {
    await apiAuth.reset({ data });
  }

  async function resetCheck(data: { token: string }) {
    await apiAuth.resetCheck({ data });
  }

  //
  // done
  //

  return {
    // state
    loggedIn,
    user,

    // actions
    forgot,
    login,
    loginWithGoogle,
    reset,
    resetCheck,
    signup,
  };
});
