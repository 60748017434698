import type { ChartSeries } from "@/lib/models";
import type { ChartOptions } from "chart.js";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { mapValues } from "es-toolkit";

//
// Style constants
//

const FONT_COLOR = "#0C0C0C";
const FONT_FAMILY = "Roboto Condensed";
const FONT_SIZE = 12;
const GRID_COLOR = "#E0E0E0";
const TOOLTIP_FONT_SIZE = 16;

export const PALETTES = mapValues(
  {
    // Based on d3PairedInv, but with a brighter color for media
    alertCategories: "#1f78b4 #ff7f0e #33a02c #b2df8a",

    // Based on d3Paired, but swich order of each pair to put darker color first
    d3PairedInv: "#1f78b4 #a6cee3 #33a02c #b2df8a #e31a1c #fb9a99 #ff7f00 #fdbf6f #6a3d9a #cab2d6 #b15928 #ffff99",

    // Based on economist style guide
    econ: "#006BA2 #3EBCD2 #EBB434 #9d394b #0f9ca3 #ac8e9a",

    // Based on d3 color schemes
    d3Category10: "#1f77b4 #ff7f0e #2ca02c #d62728 #9467bd #8c564b #e377c2 #7f7f7f #bcbd22 #17becf",
    d3Observable10: "#4269d0 #efb118 #ff725c #6cc5b0 #3ca951 #ff8ab7 #a463f2 #97bbf5 #9c6b4e #9498a0",
    d3Paired: "#a6cee3 #1f78b4 #b2df8a #33a02c #fb9a99 #e31a1c #fdbf6f #ff7f00 #cab2d6 #6a3d9a #ffff99 #b15928",
    d3Pastel1: "#fbb4ae #b3cde3 #ccebc5 #decbe4 #fed9a6 #ffffcc #e5d8bd #fddaec #f2f2f2",
    d3Set1: "#e41a1c #377eb8 #4daf4a #984ea3 #ff7f00 #ffff33 #a65628 #f781bf #999999",
    d3Set2: "#66c2a5 #fc8d62 #8da0cb #e78ac3 #a6d854 #ffd92f #e5c494 #b3b3b3",
    d3Tableau10: "#4e79a7 #f28e2c #e15759 #76b7b2 #59a14f #edc949 #af7aa1 #ff9da7 #9c755f #bab0ab",
  },
  (colors) => colors.split(" "),
);

export const DEFAULT_PALETTE: keyof typeof PALETTES = "alertCategories";

// Type guard
function isPaletteName(palette: string): palette is keyof typeof PALETTES {
  return palette in PALETTES;
}

//
// types
//

// simplified version of Chart.js's ChartDataset
export type ChartDataset = {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
};

// simplified version of Chart.js's ChartData
export type ChartData = {
  labels: string[];
  datasets: ChartDataset[];
};

//
// setup
//

export function initCharts() {
  ChartJS.register(
    BarElement,
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    SubTitle,
    TimeScale,
    Title,
    Tooltip,
  );
  ChartJS.defaults.font.family = FONT_FAMILY;
  ChartJS.defaults.font.size = FONT_SIZE;
  ChartJS.defaults.color = FONT_COLOR;
}

//
// data
//

export function buildChartData(data: ChartSeries[], options: { palette?: string } = {}): ChartData {
  // labels is the union of all the keys in the data objects
  const labels = Array.from(new Set(data.flatMap((series) => Object.keys(series.data))));

  // TODO: smart sorting

  const palette = options.palette ?? DEFAULT_PALETTE;
  if (!isPaletteName(palette)) throw new Error(`Palette not found: ${palette}`);

  const colors = PALETTES[palette];

  const datasets = data.map((series, index) => ({
    label: series.label,
    data: labels.map((label) => series.data[label] ?? 0),

    // for bar chart
    backgroundColor: colors[index % colors.length],

    // for line chart
    borderColor: colors[index % colors.length],
  }));

  return { labels, datasets };
}

//
// chart options
//

type OptionParams = {
  mobile?: boolean;

  // for bar chart
  stacked?: boolean;

  // for line chart
  points?: boolean;
  rounded?: boolean;
};

export function barChartOptions({ mobile, stacked }: OptionParams): ChartOptions<"bar"> {
  return commonChartOptions({ mobile, stacked });
}

export function lineChartOptions({ mobile, points, rounded, stacked }: OptionParams) {
  return {
    ...commonChartOptions({ mobile, stacked }),

    //
    // show/hide points
    //

    pointRadius: points ? undefined : 0,
    pointHoverRadius: points ? undefined : 0,

    //
    // rounded lines
    //

    cubicInterpolationMode: rounded ? "monotone" : undefined,
    tension: rounded ? 0.4 : 0,
  };
}

function commonChartOptions(options: OptionParams): ChartOptions<"bar"> & ChartOptions<"line"> {
  return {
    animation: false,

    // Squish the chart a bit on mobile
    aspectRatio: options.mobile ? 2.5 : 2.25,

    interaction: {
      // Show all categories in tooltip
      mode: "index",
    },

    plugins: {
      legend: {
        display: false,
      },
      subtitle: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: !options.mobile,

        //
        // styles
        //

        backgroundColor: "#fefdfb", // off-white
        bodyColor: FONT_COLOR,
        bodyFont: { size: TOOLTIP_FONT_SIZE },
        borderColor: "#dad7ca", // matches site border color
        borderWidth: 1,
        boxHeight: TOOLTIP_FONT_SIZE,
        boxWidth: TOOLTIP_FONT_SIZE,
        footerColor: FONT_COLOR,
        footerFont: { size: TOOLTIP_FONT_SIZE, weight: "normal" },
        titleMarginBottom: 8,
        footerMarginTop: 8,
        padding: 12,
        titleColor: FONT_COLOR,
        titleFont: { size: TOOLTIP_FONT_SIZE, weight: "bold" },

        // Need this plus labelPointStyle to get rid of white outline on boxes
        // https://github.com/chartjs/Chart.js/discussions/10923
        usePointStyle: true,

        // Don't show zeros in tooltip
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter: (tooltipItem: any) => tooltipItem.raw > 0,

        // Reverse the order of the items in the tooltip to match the order they
        // appear in the stacked bar chart (first item at the bottom)
        itemSort: (a, b) => {
          return b.datasetIndex - a.datasetIndex;
        },

        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          footer: (tooltipItems: any[]) => {
            let sum = 0;

            tooltipItems.forEach((tooltipItem) => {
              sum += tooltipItem.parsed.y;
            });
            return `Total - ${sum}`;
          },

          // See usePointStyle comment
          labelPointStyle: () => {
            return {
              pointStyle: "rect",
              rotation: 0,
            };
          },

          // Use "-" instead of ":" on labels
          label: function (context) {
            let label = context.dataset.label;

            if (label && context.parsed.y) {
              label += ` - ${context.parsed.y}`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        grid: {
          display: false,
        },
        stacked: !!options.stacked,
        time: {
          minUnit: "day",
          tooltipFormat: "MMM Do YYYY",
        },
        ticks: {
          maxRotation: 0, // disable rotation
        },
        afterBuildTicks: (scale) => {
          // If there are only a few ticks, it looks better to label them all
          const maxTicks = options.mobile ? 5 : 10;

          if (scale.ticks.length <= maxTicks) return;

          // There are too many ticks to label them all, so space out the labels
          // to an attractive number.
          const desiredTicks = options.mobile ? 3 : 5;
          const width = scale.ticks.length - 1; // fencepost problem
          const spaceBetweenTicks = Math.ceil(width / (desiredTicks - 1));

          scale.ticks = scale.ticks.filter((_, index) => {
            // Always include the last tick
            if (index === scale.ticks.length - 1) return true;

            // Never get too close to the last tick
            if (index > scale.ticks.length - 3) return false;

            // Space the remaining ticks evenly
            return index % spaceBetweenTicks === 0;
          });
        },
      },
      y: {
        position: "right",
        border: { display: false },
        beginAtZero: true,
        grid: {
          color: GRID_COLOR,
        },
        stacked: !!options.stacked,
        ticks: {
          autoSkip: false, // simplify tick math since maxTicksLimit is set
          maxTicksLimit: options.mobile ? 3 : 5, // ensure plenty of space between ticks
          precision: 0, // integer only
        },
      },
    },
    layout: {
      padding: 0,
    },
  };
}
